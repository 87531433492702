<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Job Date</td>
                  <td>:</td>
                  <td>xxx</td>
                </tr>
                <tr>
                  <td>Job Number</td>
                  <td>:</td>
                  <td>xxx</td>
                </tr>
                <tr>
                  <td>Job Name</td>
                  <td>:</td>
                  <td>xxx</td>
                </tr>
                <tr>
                  <td>Part Number</td>
                  <td>:</td>
                  <td>xxx</td>
                </tr>
                <tr>
                  <td>Part Description</td>
                  <td>:</td>
                  <td>xxx</td>
                </tr>
                <tr>
                  <td>Reff</td>
                  <td>:</td>
                  <td>xxx</td>
                </tr>
                <tr>
                  <td>Item/Part Number</td>
                  <td>:</td>
                  <td>xxx</td>
                </tr>
                <tr>
                  <td>Qty</td>
                  <td>:</td>
                  <td>xxx</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>:</td>
                  <td>xxx</td>
                </tr>
                <tr>
                  <td>Dept</td>
                  <td>:</td>
                  <td>xxx</td>
                </tr>
                <tr>
                  <td>Project</td>
                  <td>:</td>
                  <td>xxx</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12">
          <div>Penggunaan Bahan</div>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Description</th>
                  <th>Date</th>
                  <th>Mfr</th>
                  <th>Part Number</th>
                  <th>Part Description</th>
                  <th>Qty</th>
                  <th>UM</th>
                  <th>Price/Unit</th>
                  <th>S/N</th>
                  <th>Division</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>2</td>
                  <td>3</td>
                  <td>4</td>
                  <td>5</td>
                  <td>6</td>
                  <td>7</td>
                  <td>8</td>
                  <td>9</td>
                  <td>10</td>
                  <td>11</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td colspan="11">Total Penggunaan Bahan</td>
                  <td>xxx</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12">
          <div>Tenaga Kerja Langsung</div>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Aktivitas</th>
                  <th>Jumlah Jam Kerja</th>
                  <th>Tarif</th>
                  <th>Division</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>2</td>
                  <td>3</td>
                  <td>4</td>
                  <td>5</td>
                  <td>6</td>
                </tr>
                <tr>
                  <td colspan="5">Total Biaya Tenaga Kerja Langsung</td>
                  <td>xxx</td>
                </tr>
                <tr></tr>
                <tr>
                  <td colspan="5">Overhead Pabrik dibebankan</td>
                  <td>xxx</td>
                </tr>
                <tr></tr>
                <tr>
                  <td colspan="5">Total Harga Pokok Produksi</td>
                  <td>xxx</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style></style>
